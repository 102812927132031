import React, { Component } from 'react'
import styled from 'styled-components'
import { Typography, TextField, Button, Snackbar } from '@material-ui/core'
import { CustomPaper } from '../../CustomPaper'
import { api } from '../../../api'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

class LoginComponent extends Component {
  static propTyeps = {
    login: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      error: {
        state: false,
        text: ''
      }
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onSubmit = event => {
    event.preventDefault()
    const { email, password } = this.state
    const requestParams = {
      url: '/login/',
      method: 'POST',
      body: {
        email,
        password
      }
    }
    api(requestParams)
      .then(res => {
        this.props.login(res.sessionId)
        this.props.history.push('/')
      })
      .catch(error => {
        this.setState({
          error: {
            state: true,
            text: error.message
          }
        })
      })
  }

  onCloseError = () => {
    this.setState({
      error: {
        state: false,
        text: ''
      }
    })
  }

  render() {
    const { email, password, error } = this.state
    const { t } = this.props
    return (
      <CustomPaper>
        <StyledTypography variant="display1">
          {t('login.heading')}
        </StyledTypography>
        <Form onSubmit={this.onSubmit}>
          <StyledTextField
            label={t('login.email.label')}
            name="email"
            onChange={this.onInputChange}
            placeholder={t('login.email.placeholder')}
            required
            type="email"
            value={email}
            autoFocus
          />
          <StyledTextField
            label={t('login.password.label')}
            name="password"
            onChange={this.onInputChange}
            placeholder={t('login.password.placeholder')}
            required
            type="password"
            value={password}
          />
          <Button color="primary" variant="contained" type="submit">
            {t('login.button')}
          </Button>
        </Form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={error.state}
          onClose={this.onCloseError}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{error.text}</span>}
        />
      </CustomPaper>
    )
  }
}

export const Login = translate()(LoginComponent)

const Form = styled('form')`
  display: flex;
  flex-direction: column;
`

const StyledTextField = styled(TextField)`
  margin-right: 50px;
  margin-bottom: 30px;
`

const StyledTypography = styled(Typography)`
  margin-bottom: 25px;
`
