import React from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { ButtonLink } from '../ButtonLink'
import styled from 'styled-components'
import { CustomLink } from '../CustomLink'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

export const Header = translate()(({ isAuth, logout, t }) => {
  let content
  if (isAuth) {
    content = (
      <Wrapper>
        <IconButton color="inherit" component={CustomLink} to="/profile">
          <AccountCircle />
        </IconButton>
        <Button onClick={logout} color="inherit">
          {t('navigation.logout')}
        </Button>
      </Wrapper>
    )
  } else {
    content = (
      <Wrapper>
        <ButtonLink to="/login" color="inherit">
          {t('navigation.login')}
        </ButtonLink>
        <ButtonLink to="/register" color="inherit">
          {t('navigation.register')}
        </ButtonLink>
      </Wrapper>
    )
  }
  return (
    <AppBar component="header" position="static">
      <StyledToolbar>
        <Wrapper>
          <Typography
            component={CustomLink}
            to="/"
            variant="title"
            color="inherit"
          >
            {t('navigation.logo')}
          </Typography>
        </Wrapper>
        {content}
      </StyledToolbar>
    </AppBar>
  )
})

Header.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
}

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`
