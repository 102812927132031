import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Landing } from '../Landing'
import { Register } from '../Authorization/Register'
import { Login } from '../Authorization/Login'
import { EmailConfirm } from '../Authorization/EmailConfirm'
import { Profile } from '../Profile'
import { Demo } from '../Demo'
import { Header } from '../Header'
import styled from 'styled-components'
import { api } from '../../api'

export class App extends Component {
  constructor() {
    super()
    this.state = {
      isAuth: false
    }
  }

  componentDidMount() {
    const auth = localStorage.getItem('sessionId')
    if (auth) {
      this.setState({
        isAuth: true
      })
      const requestParams = {
        url: '/check-auth/',
        method: 'GET'
      }
      api(requestParams).catch(() => this.logout())
    }
  }

  logout = () => {
    localStorage.removeItem('sessionId')
    this.setState({
      isAuth: false
    })
  }

  login = sessionId => {
    localStorage.setItem('sessionId', sessionId)
    this.setState({
      isAuth: true
    })
  }

  render() {
    const { isAuth } = this.state
    let routes
    if (isAuth) {
      routes = (
        <Switch>
          <Route path="/profile" component={Profile} />
          <Route exact path="/" component={Demo} />
          <Route component={Demo} />
        </Switch>
      )
    } else {
      routes = (
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/register" component={Register} />
          <Route
            path="/login"
            render={({ history }) => (
              <Login login={this.login} history={history} />
            )}
          />
          <Route path="/email-confirm" component={EmailConfirm} />
          <Route component={Landing} />
        </Switch>
      )
    }
    return (
      <Wrapper>
        <Header isAuth={isAuth} logout={this.logout} />
        {routes}
      </Wrapper>
    )
  }
}

const Wrapper = styled('div')`
  padding-bottom: 100px;
`
