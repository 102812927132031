import React from 'react'
import { CustomPaper } from '../../CustomPaper'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ButtonLink } from '../../ButtonLink'
import { translate } from 'react-i18next'

export const EmailConfirm = translate()(props => {
  const { t } = props
  if (props.location.search === '?success=true') {
    return (
      <CustomPaper>
        <StyledTypography variant="display1">
          {t('emailConfirm.verified.heading')}
        </StyledTypography>
        <StyledTypography>{t('emailConfirm.verified.text')}</StyledTypography>
        <ButtonLink to="/login" variant="contained" fullWidth={true}>
          {t('navigation.login')}
        </ButtonLink>
      </CustomPaper>
    )
  }
  return (
    <CustomPaper>
      <StyledTypography variant="display1">
        {t('emailConfirm.notVerified.heading')}
      </StyledTypography>
      <Typography>{t('emailConfirm.notVerified.text')}</Typography>
    </CustomPaper>
  )
})

const StyledTypography = styled(Typography)`
  margin-bottom: 30px;
`
