import React, { Component } from 'react'
import { CustomPaper } from '../CustomPaper'
import { Typography } from '@material-ui/core'
import { ButtonLink } from '../ButtonLink'
import { api } from '../../api'
import styled from 'styled-components'
import { Camera } from '../Camera'
import { translate } from 'react-i18next'

class DemoComponent extends Component {
  constructor() {
    super()
    this.state = {
      photos: [],
      result: null
    }
  }
  componentDidMount() {
    const requestParams = {
      method: 'GET',
      url: '/profile-data'
    }
    api(requestParams).then(data => {
      const photos = data.photos
      if (photos) {
        this.setState({ photos: data.photos })
      }
    })
  }

  capture = base64 => {
    let allPhotos = this.state.photos
    const masterPhotos = []
    while (masterPhotos.length < 3) {
      const index = Math.round((allPhotos.length - 1) * Math.random())
      masterPhotos.push(allPhotos[index])
      allPhotos = allPhotos.filter(
        (item, currentIndex) => currentIndex !== index
      )
    }
    const requestParams = {
      url: '/demo',
      method: 'POST',
      body: {
        base64,
        masterPhotos
      }
    }
    api(requestParams).then(({ value }) => this.setState({ result: value }))
  }

  render() {
    const { photos, result } = this.state
    const { t } = this.props
    let content
    if (photos.length < 3) {
      content = (
        <React.Fragment>
          <Typography variant="body1">{t('demo.notReady.text')}</Typography>
          <ButtonLink to="/profile">{t('demo.notReady.button')}</ButtonLink>
        </React.Fragment>
      )
    } else {
      content = <Camera capture={this.capture} />
    }
    return (
      <CustomPaper>
        <Typography variant="display1">{t('demo.heading')}</Typography>
        <Wrapper>{content}</Wrapper>
        {result !== null && (
          <Result result={result}>
            <Typography variant="headline">
              {result ? 'Authorized' : 'Unauthorized'}
            </Typography>
          </Result>
        )}
      </CustomPaper>
    )
  }
}

export const Demo = translate()(DemoComponent)

const Wrapper = styled('div')`
  padding-top: 50px;
  margin-bottom: 16px;
`

const Result = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 256px;
  height: 64px;
  background-color: ${props => (props.result ? '#76ff03' : '#ff1744')};
`
