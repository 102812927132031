import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { translate } from 'react-i18next'

export const Navigation = translate()(({ offset, onPaginate, limit, t }) => (
  <Wrapper>
    {offset > 0 ? (
      <Button
        variant="contained"
        color="primary"
        onClick={() => onPaginate(-1)}
      >
        {t('profile.photos.prev')}
      </Button>
    ) : (
      <div />
    )}
    {offset < limit - 1 ? (
      <Button variant="contained" color="primary" onClick={() => onPaginate(1)}>
        {t('profile.photos.next')}
      </Button>
    ) : (
      <div />
    )}
  </Wrapper>
))

Navigation.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPaginate: PropTypes.func.isRequired
}

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`
