import React, { Component } from 'react'
import styled from 'styled-components'
import { Camera } from '../../Camera'
import { api } from '../../../api'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

class CameraCaptureComponent extends Component {
  static propTypes = {
    getData: PropTypes.func.isRequired,
    onMessage: PropTypes.func.isRequired
  }

  capture = base64 => {
    const requestParams = {
      url: '/photos-manage/base64/',
      method: 'POST',
      body: { base64 }
    }
    api(requestParams).then(() => {
      this.props.getData()
      this.props.onMessage({
        message: this.props.t('profile.upload.tabs.camera.success')
      })
    })
  }

  render() {
    return (
      <Wrapper>
        <Camera capture={this.capture} />
      </Wrapper>
    )
  }
}

export const CameraCapture = translate()(CameraCaptureComponent)

const Wrapper = styled('div')`
  padding-top: 30px;
`
