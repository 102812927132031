import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import WebCam from 'react-webcam'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { translate } from 'react-i18next'

class CameraComponent extends Component {
  static propTypes = {
    capture: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      isCameraOn: false
    }
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  capture = () => {
    this.props.capture(this.webcam.getScreenshot())
  }

  turnWebCamOn = () => {
    this.setState({
      isCameraOn: true
    })
  }

  render() {
    const { isCameraOn } = this.state
    const { t } = this.props
    let content = (
      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={this.turnWebCamOn}
      >
        {t('camera.openButton')}
      </Button>
    )
    if (isCameraOn) {
      content = (
        <React.Fragment>
          <MediaQuery minDeviceWidth={700}>
            {matches => (
              <WebCam
                audio={false}
                ref={this.setRef}
                screenshotFormat="image/jpeg"
                width={matches ? 600 : 300}
              />
            )}
          </MediaQuery>

          <CustomButton
            variant="contained"
            color="primary"
            onClick={this.capture}
            fullWidth={true}
          >
            {t('camera.actionButton')}
          </CustomButton>
        </React.Fragment>
      )
    }
    return <Wrapper>{content}</Wrapper>
  }
}

export const Camera = translate()(CameraComponent)

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const CustomButton = styled(Button)`
  margin-top: 20px;
`
