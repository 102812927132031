import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography, TextField, Button, Snackbar } from '@material-ui/core'
import { api } from '../../../api'
import { CustomPaper } from '../../CustomPaper'
import { translate } from 'react-i18next'

class RegisterComponent extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  constructor() {
    super()
    this.state = {
      firstName: '',
      lastName: '',
      passwordFirst: '',
      passwordSecond: '',
      email: '',
      error: {
        state: false,
        text: ''
      }
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onSubmit = event => {
    event.preventDefault()
    const {
      firstName,
      lastName,
      email,
      passwordFirst,
      passwordSecond
    } = this.state
    if (passwordFirst === passwordSecond) {
      const requestParams = {
        url: '/register/',
        method: 'POST',
        body: {
          firstName,
          lastName,
          email,
          password: passwordFirst
        }
      }
      api(requestParams)
        // .then(() => this.props.history.push('/email-confirm'))
        .then(() => this.props.history.push('/'))
        .catch(error => {
          this.setState({
            error: {
              state: true,
              text: error.message
            }
          })
        })
    } else {
      this.setState({
        error: {
          state: true,
          text: this.props.t('register.passwordsNotMatch')
        }
      })
    }
  }

  onCloseError = () => {
    this.setState({
      error: {
        state: false,
        text: ''
      }
    })
  }

  render() {
    const {
      firstName,
      lastName,
      passwordFirst,
      passwordSecond,
      email,
      error
    } = this.state
    const { t } = this.props
    return (
      <CustomPaper>
        <StyledTypography variant="display1">
          {t('register.heading')}
        </StyledTypography>
        <Form onSubmit={this.onSubmit}>
          <Row>
            <StyledTextField
              autoFocus
              label={t('register.firstName.label')}
              name="firstName"
              onChange={this.onInputChange}
              placeholder={t('register.firstName.placeholder')}
              type="text"
              value={firstName}
            />
            <StyledTextField
              label={t('register.lastName.label')}
              name="lastName"
              onChange={this.onInputChange}
              placeholder={t('register.lastName.placeholder')}
              type="text"
              value={lastName}
            />
          </Row>
          <StyledTextField
            label={t('register.passwordFirst.label')}
            name="passwordFirst"
            onChange={this.onInputChange}
            placeholder={t('register.passwordFirst.placeholder')}
            helperText={t('register.passwordFirst.helper')}
            required
            type="password"
            value={passwordFirst}
            inputProps={{ pattern: '.{8,}' }}
          />
          <StyledTextField
            label={t('register.passwordSecond.label')}
            name="passwordSecond"
            onChange={this.onInputChange}
            placeholder={t('register.passwordSecond.placeholder')}
            required
            type="password"
            value={passwordSecond}
            inputProps={{ pattern: '.{8,}' }}
          />
          <StyledTextField
            label={t('register.email.label')}
            name="email"
            onChange={this.onInputChange}
            placeholder={t('register.email.placeholder')}
            required
            type="email"
            value={email}
          />
          <Button color="primary" variant="contained" type="submit">
            {t('register.button')}
          </Button>
        </Form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={error.state}
          onClose={this.onCloseError}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{error.text}</span>}
        />
      </CustomPaper>
    )
  }
}

export const Register = translate()(RegisterComponent)

const Form = styled('form')`
  display: flex;
  flex-direction: column;
`

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: 500px) {
    flex-wrap: nowrap;
  }
`

const StyledTextField = styled(TextField)`
  margin-right: 50px;
  margin-bottom: 30px;
`

const StyledTypography = styled(Typography)`
  margin-bottom: 25px;
`
