import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { fileApi } from '../../../api'
import { translate } from 'react-i18next'
import ImageCompressor from 'image-compressor.js'

class FilesComponent extends Component {
  static propTypes = {
    getData: PropTypes.func.isRequired,
    onMessage: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      selectedPhotos: [],
      isUploading: false
    }
  }

  onPhotosSubmit = event => {
    event.preventDefault()
    if (this.state.selectedPhotos.length > 0) {
      const formData = new FormData()
      this.state.selectedPhotos.forEach(photo => {
        formData.append('photo', photo)
      })
      const requestParams = {
        url: '/photos-manage',
        body: formData
      }
      this.setState({
        isUploading: true
      })
      fileApi(requestParams)
        .then(() => {
          this.setState({
            selectedPhotos: [],
            isUploading: false
          })
          this.props.getData()
        })
        .catch(error => {
          this.props.onMessage(error)
          this.setState({
            selectedPhotos: [],
            isUploading: false
          })
        })
    }
  }

  onFileInput = event => {
    const files = [...event.target.files]
    const imageCompressor = new ImageCompressor()
    files.forEach(file => {
      imageCompressor
        .compress(file, {
          quality: 0.6,
          maxWidth: 1000,
          convertSize: 1000
        })
        .then(compressedFile => {
          this.setState(prevState => ({
            selectedPhotos: [...prevState.selectedPhotos, compressedFile]
          }))
        })
        .catch(error => {
          console.log(error.message)
          this.props.onMessage(error.message)
        })
    })
  }

  render() {
    const { selectedPhotos, isUploading } = this.state
    const { t } = this.props
    return (
      <Form onSubmit={this.onPhotosSubmit}>
        <CustomInput
          onChange={this.onFileInput}
          accept="image/*"
          id="file-input"
          multiple
          type="file"
        />
        <label htmlFor="file-input">
          <Button variant="contained" component="span">
            {t('profile.upload.tabs.files.select')}
          </Button>
        </label>
        <Typography variant="body2">
          {t('profile.upload.tabs.files.text1')} {selectedPhotos.length}{' '}
          {t('profile.upload.tabs.files.text2')}
        </Typography>
        <SubmitWrapper>
          {isUploading && <CustomCircularProgress />}
          <Button type="submit" variant="contained" color="primary">
            {t('profile.upload.tabs.files.submit')}
          </Button>
        </SubmitWrapper>
      </Form>
    )
  }
}

export const Files = translate()(FilesComponent)

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`

const CustomInput = styled('input')`
  display: none;
`

const SubmitWrapper = styled('div')`
display: flex
align-items:center;
`

const CustomCircularProgress = styled(CircularProgress)`
  margin-right: 20px;
`
