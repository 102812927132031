import React from 'react'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const CustomPaper = ({ children }) => (
  <Wrapper>
    <StyledPaper>{children}</StyledPaper>
  </Wrapper>
)

CustomPaper.propTypes = {
  children: PropTypes.node.isRequired
}

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
`

const StyledPaper = styled(Paper)`
  padding: 20px;
  @media (min-width: 500px) {
    padding: 70px;
    min-width: 500px;
  }
`
