const root = process.env.REACT_APP_HOST

const headers = file => {
  const sessionId = localStorage.getItem('sessionId')
  const result = {}
  if (sessionId) {
    result.Authorization = sessionId
  }
  if (!file) {
    result.Accept = 'application/json'
    result['Content-Type'] = 'application/json'
  }
  return result
}

const parseResponse = response => {
  return response.json().then(json => {
    if (response.ok) {
      return json
    } else if (response.status === 413) {
      return Promise.reject({ message: 'Size of files is too big' })
    }
    return Promise.reject(json)
  })
}

const parsePhotoResponse = response => {
  return response.blob().then(blob => blob)
}

export const api = ({ url, method, body }) => {
  return fetch(root + url, {
    method: method,
    headers: headers(),
    body: JSON.stringify(body)
  }).then(parseResponse)
}

export const fileApi = ({ url, body }) => {
  return fetch(root + url, {
    method: 'PUT',
    headers: headers(true),
    body
  }).then(parseResponse)
}

export const getPhotoApi = name => {
  return fetch(`${root}/photos/${name}`, {
    method: 'GET',
    headers: headers(true)
  }).then(parsePhotoResponse)
}
