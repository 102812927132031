import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@material-ui/core'
import Description from '@material-ui/icons/Description'
import Videocam from '@material-ui/icons/Videocam'
import { Files } from './Files'
import { CameraCapture } from './CameraCapture'
import { translate } from 'react-i18next'

class UploadComponent extends Component {
  static propTypes = {
    getData: PropTypes.func.isRequired,
    onMessage: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      currentTab: 0
    }
  }

  onTabChange = (event, value) => {
    this.setState({
      currentTab: value
    })
  }

  render() {
    const { currentTab } = this.state
    const { getData, onMessage, t } = this.props
    return (
      <Wrapper>
        <Tabs
          value={this.state.currentTab}
          onChange={this.onTabChange}
          fullWidth
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            icon={<Description />}
            label={t('profile.upload.tabs.files.heading')}
          />
          <Tab
            icon={<Videocam />}
            label={t('profile.upload.tabs.camera.heading')}
          />
        </Tabs>
        {currentTab === 0 && <Files getData={getData} onMessage={onMessage} />}
        {currentTab === 1 && (
          <CameraCapture getData={getData} onMessage={onMessage} />
        )}
      </Wrapper>
    )
  }
}

export const Upload = translate()(UploadComponent)

const Wrapper = styled('div')`
  @media (min-width: 600px) {
    min-width: 600px;
  }
`
