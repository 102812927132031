import React from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core'
import styled from 'styled-components'
import bg from './bg.jpg'
import { ButtonLink } from '../ButtonLink'
import { translate } from 'react-i18next'

export const Landing = translate()(props => {
  const { t } = props
  return (
    <Wrapper>
      <StyledCard>
        <StyledCardMedia image={bg} title="Contemplative Reptile" />
        <CardContent>
          <Typography gutterBottom variant="headline" component="h2">
            {t('landing.heading')}
          </Typography>
          <Typography component="p">{t('landing.text')}</Typography>
        </CardContent>
        <CardActions>
          <ButtonLink to="/login">{t('navigation.login')}</ButtonLink>
          <ButtonLink to="/register">{t('navigation.register')}</ButtonLink>
        </CardActions>
      </StyledCard>
    </Wrapper>
  )
})

const Wrapper = styled('main')`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

const StyledCard = styled(Card)`
  max-width: 700px;
`

const StyledCardMedia = styled(CardMedia)`
  height: 300px;
`
