import React, { Component } from 'react'
import { CustomPaper } from '../CustomPaper'
import { Typography, Snackbar } from '@material-ui/core'
import { api } from '../../api'
import styled from 'styled-components'
import { PhotoList } from './PhotoList'
import { Upload } from './Upload'
import { translate } from 'react-i18next'

class ProfileComponent extends Component {
  constructor() {
    super()
    this.state = {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        photos: []
      },
      offset: 0,
      message: {
        state: false,
        text: ''
      }
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    const requestParams = {
      method: 'GET',
      url: '/profile-data'
    }
    api(requestParams).then(data => {
      let newData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        photos: []
      }
      if (data.photos) {
        newData.photos = data.photos
      }
      this.setState({ data: newData })
    })
  }

  onCloseMessage = () => {
    this.setState({
      message: {
        state: false,
        text: ''
      }
    })
  }

  onDeletePhoto = photo => {
    const requestParams = {
      url: '/photos-manage/',
      method: 'DELETE',
      body: photo
    }
    api(requestParams).then(() =>
      this.setState(prevState => ({
        data: {
          firstName: prevState.data.firstName,
          lastName: prevState.data.lastName,
          email: prevState.data.email,
          photos: prevState.data.photos.filter(item => item !== photo)
        }
      }))
    )
  }

  onPaginate = offset => {
    this.setState(prevState => ({
      offset: prevState.offset + offset
    }))
  }

  onMessage = data => {
    this.setState({
      message: {
        state: true,
        text: data.message
      }
    })
  }

  onChangeImage = (index, newName) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        photos: [
          ...prevState.data.photos.map(
            (item, itemIndex) =>
              itemIndex === index ? { filename: newName } : item
          )
        ]
      }
    }))
  }

  render() {
    const {
      offset,
      message: { state, text },
      data: { firstName, lastName, email, photos }
    } = this.state
    const { t } = this.props

    const photosPerPage = 9

    const limit = Math.ceil(photos.length / photosPerPage)

    let photosList
    if (photos) {
      const currentIndex = offset * photosPerPage
      const photosSliced = photos.slice(
        currentIndex,
        currentIndex + photosPerPage
      )
      photosList = (
        <PhotoList
          photos={photosSliced}
          onDelete={this.onDeletePhoto}
          offset={offset}
          onPaginate={this.onPaginate}
          limit={limit}
          onChangeImage={this.onChangeImage}
        />
      )
    }

    let name
    if (firstName.length || lastName.length) {
      name = `${firstName} ${lastName}`
    } else {
      name = email
    }
    return (
      <React.Fragment>
        <CustomPaper>
          <Typography variant="display1">{t('profile.heading')}</Typography>
          <Typography variant="subheading">{name}</Typography>
          <PhotosSection>
            <Typography variant="title">
              {t('profile.photos.heading')}
            </Typography>
            {photosList}
          </PhotosSection>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={state}
            onClose={this.onCloseMessage}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">{text}</span>}
          />
        </CustomPaper>
        <CustomPaper>
          <Upload getData={this.getData} onMessage={this.onMessage} />
        </CustomPaper>
      </React.Fragment>
    )
  }
}

export const Profile = translate()(ProfileComponent)

const PhotosSection = styled('section')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  min-height: 130px;
  @media (min-width: 600px) {
    min-width: 600px;
  }
`
