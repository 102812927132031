import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './components/App'
// import registerServiceWorker from './registerServiceWorker'
import { BrowserRouter } from 'react-router-dom'
import 'normalize.css'
import 'typeface-roboto'
import CssBaseline from '@material-ui/core/CssBaseline'
import { JssProvider } from 'react-jss'
import { create } from 'jss'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const generateClassName = createGenerateClassName()
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')
})

ReactDOM.render(
  <BrowserRouter>
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <CssBaseline>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </CssBaseline>
    </JssProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// registerServiceWorker()
