import React, { Component } from 'react'
import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Navigation } from './Navigation'
import Delete from '@material-ui/icons/Delete'
import Replay from '@material-ui/icons/Replay'
import styled from 'styled-components'
import { api } from '../../api'
import defaultImg from './default.svg'
import MediaQuery from 'react-responsive'

export class PhotoList extends Component {
  static propTypes = {
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    onDelete: PropTypes.func.isRequired,
    offset: PropTypes.number.isRequired,
    onPaginate: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    onChangeImage: PropTypes.func.isRequired
  }

  constructor() {
    super()
    this.state = {
      rotatingItem: ''
    }
  }

  onRotate = (item, index, deg) => {
    const requestParams = {
      url: '/photos-manage/rotate/',
      method: 'POST',
      body: { item, index, deg }
    }
    this.setState({
      rotatingItem: item.filename
    })
    api(requestParams)
      .then(response => {
        this.props.onChangeImage(index, response.newName)
        this.setState({
          rotatingItem: ''
        })
      })
      .catch(() =>
        this.setState({
          rotatingItem: ''
        })
      )
  }

  render() {
    const { photos, onDelete, offset, onPaginate, limit } = this.props
    const { rotatingItem } = this.state
    const photosData = photos.map((item, index) => {
      let img = <img src={defaultImg} alt="" />
      if (item.filename !== rotatingItem) {
        img = (
          <img
            src={`${process.env.REACT_APP_HOST}/photos/${item.filename}`}
            alt={item.name}
          />
        )
      }
      return (
        <GridListTile key={item.filename}>
          {img}
          {rotatingItem === item.filename && (
            <CustomCircularProgress size={80} />
          )}
          <CustomGridListTileBar
            titlePosition="bottom"
            actionIcon={
              <React.Fragment>
                <CustomIconButton
                  onClick={() => this.onRotate(item, index, -90)}
                >
                  <Replay />
                </CustomIconButton>
                <CustomIconButton
                  onClick={() => this.onRotate(item, index, 90)}
                >
                  <FlippedReplay />
                </CustomIconButton>
                <CustomIconButton onClick={() => onDelete(item)}>
                  <Delete />
                </CustomIconButton>
              </React.Fragment>
            }
            actionPosition="right"
          />
        </GridListTile>
      )
    })
    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={800}>
          {matches => (
            <GridList cols={matches ? 3 : 2} cellHeight={matches ? 300 : 200}>
              {photosData}
            </GridList>
          )}
        </MediaQuery>
        <Navigation offset={offset} limit={limit} onPaginate={onPaginate} />
      </React.Fragment>
    )
  }
}

const CustomGridListTileBar = styled(GridListTileBar)`
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0) 100%
  );
`

const CustomIconButton = styled(IconButton)`
  color: white;
`

const FlippedReplay = styled(Replay)`
  transform: rotateY(180deg);
`

const CustomCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
`
