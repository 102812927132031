import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

const LinkWrapper = props => <Link {...props} />

export const ButtonLink = props => (
  <Button component={LinkWrapper} {...props}>
    {props.children}
  </Button>
)

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string
}

ButtonLink.defaultProps = {
  color: 'primary',
  size: 'medium'
}
