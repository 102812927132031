import i18n from 'i18next'
import fetch from 'i18next-fetch-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(fetch)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_HOST}/locales/{{lng}}/{{ns}}.json`
    },
    fallbackLng: 'en',
    debug: true,
    returnEmptyString: true,
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    // react i18next special options (optional)
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  })

export default i18n
