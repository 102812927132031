import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const CustomLink = props => <StyledLink {...props} />

CustomLink.propTypes = {
  to: PropTypes.string.isRequired
}

const StyledLink = styled(Link)`
  text-decoration: none;
`
